<template>
    <div class="body">
        <!--导航栏--->
        <section class="top">
            <van-nav-bar title="发表评价" safe-area-inset-top left-arrow :fixed='isTop' @click-left="onClickLeft" />
        </section>
        <!-- 商品信息 -->
        <section class="shopInfo">
            <!-- <span>{{ ceshi }}</span> -->
            <div class="goodsInfo" v-for="(v, i) in shopsGoods" :key="i">
                <div class="goodsList">
                    <div class="goodsImg">
                        <van-image fit="cover" :src="v[0].picUrl" />
                    </div>
                    <div class='goodsText'>
                        <p>{{ v[0].spuName }}</p>
                        <div class="size fontColor">
                            <span>规格：{{ v[0].specInfo || '...' }}</span>
                            <span>x{{ v[0].quantity }}</span>
                        </div>
                    </div>
                </div>
                <div class="goodsStar">
                    <p><span>描述相符</span> <van-rate v-model="starList[i]" /></p>
                </div>

                <div class="evaluateDetail">
                    <van-field v-model="contentList[i]" type="textarea" placeholder="与描述是否一致？商品质量怎么样？" show-word-limit
                        maxlength="200" />
                    <div class="uploadImg">
                        <van-uploader v-model="fileLists[i]" multiple preview-size="2.6rem" :max-count="4"
                            :before-delete="deletePics" :after-read="afterRead" :name="i" accept="image/*" />
                    </div>
                </div>
            </div>
        </section>
        <!-- 物流和商家服务 -->
        <!--  <section class="SumEvaluate">
                <div class="goodsStar">
                    <p><span>物流服务</span><van-rate v-model="logisticsStar" /></p>
                </div>
                <div class="goodsStar">
                    <p><span>商家服务</span><van-rate v-model="serveStar" /></p>
                </div>
            </section> -->
        <!-- 提交按钮 -->
        <section class="submitEvaluate">
            <van-button round type="info" color="#E54320" block @click="submitEvaluate">提交</van-button>
        </section>
    </div>
</template>

<script>
import { sendGoodsEvaluate } from '@/api/yundian/goodsEvaluate/index'
import { getOrderIdDetail } from '@/api/yundian/orderIdDetail/index.js'
import { Toast, Dialog } from 'vant'
import { upload } from '@/api/tsMallorderDetail'
export default {
    data() {
        return {
            beforeUrl: '',//上级路由
            isTop: true,
            // showupload: true,
            shopsGoods: [],//商品信息
            fileLists: [],//图片列表
            starList: [],//星星列表
            // logisticsStar: 0,//物流星星
            // serveStar: 0,//商家服务星星
            contentList: [],//评论文字
            evaluateList: [],//评分列表
            imgArr: [],//图片列表
            beforeRoute: '',//进来的路由
            timer: null,//定时器
            ceshi: '',
        }
    },
    beforeMount() {
        this.getPage()
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    /* activated() {
        // console.log('11111');
        // Toast('1111')
        this.fileLists = []
    }, */
    /* updated() {
        // Toast('2222')
    }, */
    //前置守卫，判断前一个路由
    /*  beforeRouteEnter(to, from, next) {
         next(vm => {
             //  这里的vm指的就是vue实例，可以用来当做this使用
             vm.fromPath = from.path  //获取上一级路由的路径
             vm.beforeRoute = from.path
             // console.log(vm.fromPath, 'fromPath')
             }
         })
     }, */
    methods: {
        getPage() {
            if (this.$route.query.id) {
                let id = this.$route.query.id
                this.$store.commit('setSession', this.$route.query.session)
                getOrderIdDetail({ id, types: '1' }).then(res => {
                    if (res.data.code === 0) {
                        this.shopsGoods = res.data.data.listOrderItems//渲染订单列表
                        this.contentList = res.data.data.listOrderItems.map(v => {
                            v = ''
                            return v
                        })
                        this.starList = res.data.data.listOrderItems.map(v => {
                            v = 0
                            return v
                        })
                        this.evaluateList = res.data.data.listOrderItems.map((item) => {
                            let goodsEvaluate = {
                                orderId: '',
                                itemId: '',
                                goodsScore: '',
                                serviceScore: '',
                                logisticsScore: '',
                                content: '',
                                resonUrl: [],
                            }
                            goodsEvaluate.itemId = item[0].itemId
                            goodsEvaluate.orderId = item[0].orderId
                            return goodsEvaluate
                        })
                        //图片列表
                        this.fileLists = res.data.data.listOrderItems.map((item) => {
                            item = []
                            return item
                        })
                    }
                }).catch(err => {
                    Toast.fail(`数据获取失败，请退出重试${err}`)
                })
            } else {
                this.shopsGoods = this.$route.query.data
                this.contentList = this.$route.query.data.map(v => {
                    v = ''
                    return v
                })
                this.starList = this.$route.query.data.map(v => {
                    v = 0
                    return v
                })
                this.evaluateList = this.$route.query.data.map((item) => {
                    let goodsEvaluate = {
                        orderId: '',
                        itemId: '',
                        goodsScore: '',
                        serviceScore: '',
                        logisticsScore: '',
                        content: '',
                        resonUrl: [],

                    }
                    goodsEvaluate.itemId = item[0].itemId
                    goodsEvaluate.orderId = item[0].orderId
                    return goodsEvaluate
                })
                //图片列表
                this.fileLists = this.$route.query.data.map((item) => {
                    item = []
                    return item
                })
            }
        },
        //点击提交评价
        submitEvaluate() {
            // let _this = this
            //商品评价
            let subEvaluate = this.evaluateList.map((v, i) => {
                v.orderId = this.shopsGoods[i][0].orderId
                v.itemId = this.shopsGoods[i][0].itemId
                v.goodsScore = this.starList[i] + ''
                v.content = this.contentList[i]
                // v.serviceScore = this.serveStar + ''
                // v.logisticsScore = this.logisticsStar + ''
                v.resonUrl = v.resonUrl.join(',')
                // console.log(v);
                return v
            })
            console.log(subEvaluate, '<==提交内容');
            sendGoodsEvaluate(subEvaluate).then((res) => {
                if (res.data.code === 0) {
                    Toast.success('评价成功')
                    this.timer = setTimeout(() => {
                        this.backToWhere()
                    }, 500);
                }

            }).catch(err => {
                Toast.fail(err)
            })
        },
        //点击返回
        onClickLeft() {
            if (this.beforeRoute === '/yundian/orderIdDetail') {
                this.$router.back(-1)
            } else {
                // Toast('走不走？')
                this.backToHere()
            }
        },
        //原生交互返回方法
        backToWhere() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.ydMallCommentSuccess.postMessage("");
            }
            if (isAndroid) {
                window.android.ydMallCommentSuccess();
            }
        },
        //返回原生不刷新
        backToHere() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.ydMallOrderCancel.postMessage("");
            }
            if (isAndroid) {
                window.android.ydMallOrderCancel();
            }
        },
        //上传照片
        /*  afterRead(file, detail) {
             // console.log(file, '上传')
             // console.log(detail, 'detail')
             file.status = 'uploading'
             file.message = '上传中'
             let formData = new window.FormData()
             formData.append('file', file.file)
             formData.append('fileType', 'imgage/png'),
                 formData.append('dir', 'material')
             // console.log(formData, '参数哦')
             upload(formData).then(res => {
                 if (res.data.code === 0) {
                     file.status = ''
                     file.message = ''
                     this.evaluateList[detail.name].resonUrl.push(res.data.data)
                 }
             }).catch(() => {
                 file.status = 'failed',
                     file.message = '上传失败'
             })
         }, */
        afterRead(file, detail) {
            // 此时可以自行将文件上传至服务器
            console.log(file, '<===文件');
            // console.log(detail, '<===detail文件');
            if (file.length > 1) {
                //使用for循环来适配多选情况
                for (let i in file) {
                    file[i].status = 'uploading'
                    file[i].message = '上传中'
                    this.upImg(file[i], detail)
                }
            } else {
                file.status = 'uploading'
                file.message = '上传中'
                this.upImg(file, detail)
            }
        },
        //上传图片封装
        upImg(file, detail) {
            console.log(file, '<==文件2');
            let formData = new window.FormData()
            formData.append('file', file.file)
            formData.append('fileType', 'imgage/png'),
                formData.append('dir', 'material')
            // console.log(formData, '参数哦')
            upload(formData).then(res => {
                if (res.data.code === 0) {
                    file.status = ''
                    file.message = ''
                    this.evaluateList[detail.name].resonUrl.push(res.data.data)
                }
            }).catch(() => {
                file.status = 'failed',
                    file.message = '上传失败'
            })
        },

        //删除照片
        deletePics(file, detail) {
            Dialog.confirm({
                title: '截图',
                message: '确认删除截图？'
            }).then(() => {
                this.fileLists[detail.name].splice(detail.index, 1)
                this.evaluateList[detail.name].resonUrl.splice(detail.index, 1)
            }).catch(() => {
                Toast.fail('已取消')
            })
        },
        beforeRead(file) {
            console.log(file, '???');
            if (file.length > 1) {
                //使用for循环来适配多选情况
                for (let i in file) {
                    if (file[i].type !== 'image/jpeg') {
                        Toast('请上传 jpg 格式图片');
                        return false;
                    }
                    return true

                }
            } else {
                if (file.type !== 'image/jpeg') {
                    Toast('请上传 jpg 格式图片');
                    return false;
                }
                return true;
            }
        },
        closePreview(file) {
            console.log(file, '!!!!');
        },
        clickUpload(file) {
            // this.ceshi = file
            // this.showupload = true
            console.log(file, '.....');
            // Toast('5555')
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    height: 100%;
    background: #f7f7f7;

    .fontColor {
        line-height: 0.7784rem;
        color: #666666;
        font-size: 12px;
    }

    .goodsStar {
        padding: 0.4324rem 0;

        p {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                margin-right: 0.3459rem;
            }
        }
    }

    section {
        background: #fff;

    }

    .top {
        height: 6%;
        border-bottom: 1px solid #E5E5E5;
        font-size: 1.2973rem;
    }

    .shopInfo {
        display: flex;
        flex-flow: column nowrap;
        padding: 0.4324rem 0.5189rem;

        .goodsInfo {
            display: flex;
            flex-flow: column nowrap;
            padding-top: 0.4324rem;
            margin-bottom: 0.4324rem;

            .goodsList {
                display: flex;
                padding: 0.2rem 0 0 0;
                flex-flow: row nowrap;
                margin-bottom: 0.4324rem;

                .goodsImg {
                    width: 2rem;
                    // height: 2rem
                }

                .goodsText {
                    margin-left: 0.5189rem;
                    display: flex;
                    flex: 1;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    p {
                        width: 5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .evaluateDetail {
                background: #F7F7F7;
                // padding: 0.2rem;

                .van-field {
                    background: #F7F7F7;
                }

                .uploadImg {
                    margin: 0.4rem 0 0.4rem 0.4rem;

                    /deep/.van-uploader__upload {
                        border: 1px dashed #999999;
                        margin: 1px;
                    }

                    /deep/.van-uploader__upload input {
                        margin: 0;
                        width: 100%;
                    }

                }

            }
        }

    }

    .SumEvaluate {
        padding: 0.173rem 0.5189rem;
    }

    .submitEvaluate {
        box-sizing: border-box;
        padding: 0.4324rem 0.5189rem;
    }

}
</style>